import * as React from 'react'
import './Footer.scss'
import candado from '../../assets/candado.svg'
import cruce from '../../assets/cruce.svg'
import vtex from '../../assets/vtex.svg'
import fiscal from '../../assets/fiscal.svg'
import { Container } from 'reactstrap'


export default function Footer() {
  return (
    <div className='footer d-flex align-items-center' style={{borderTop: '1px solid #c8c8c8'}}>
      <Container className='footer d-flex align-items-center' fluid>
        <p className="d-flex align-items-center mb-0 mr-4" style={{color: '#7DC832'}}><img src={candado} className="mr-1 footer-hold"/> Compra segura</p>
        <p className="mb-0">© Farmacias Del Pueblo</p>
        <div className="ml-auto">
          <img src={cruce} className="mr-4 footer-logo-cruce" />
          <img src={vtex} className="mr-4 footer-logo-vtex" />
          <img src={fiscal} className="footer-logo-fiscal"/>
        </div>
      </Container>
    </div>
  );
};
