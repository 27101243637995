import * as React from 'react'
import { Modal, ModalBody, Button, Row, Col } from 'reactstrap';
import timer from '../../assets/timer.svg';

var interval;
var duration = 300
var plus = false;
var plusDuration = 180

export default function Timer(props) {
    const [modal, setModal] = React.useState(false)
    const [countdown, setCountdown] = React.useState('')
    const [color, setColor] = React.useState('text-secundario')

    const restart = () => {
        setModal(false)
        clearInterval(interval)
        plus = false;
        setColor('text-secundario')
        start(duration)
    }

    const start = (duration) => {
        var timer = duration, minutes, seconds;
        try {
            clearInterval(interval)
        } catch(err) {
            //pass
        }
    
        interval = setInterval(function () {
            minutes = parseInt(timer / 60, 10);
            seconds = parseInt(timer % 60, 10);
    
            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;
    
            if(timer === 0) {
                setModal(true)
                timer = plusDuration
                if(plus) {
                    window.location.href = '/'
                }
                plus = true;
                setColor('text-alerta')
            }
            
            setCountdown(minutes + ":" + seconds)
    
            timer--

            // if (--timer < 0) {
            //     timer = 0;
            // }
        }, 1000);
    };

    React.useEffect(() => {
        start(duration)
    },  [])

    return (
        <>
            <div className="mt-auto pt-3 pb-3 t1-r">TIEMPO RESTANTE <b className={`t1-b ml-4 ${color}`}>{countdown}</b></div>

            <Modal isOpen={modal} toggle={() => setModal(false)} className="alert-error">
                <ModalBody>
                    <h2 className="text-danger d-flex align-items-center tit-3-r text-negro"><img src={timer} className="mr-2" style={{width: '1.816vw'}} />¿Necesitás más tiempo?</h2>
                    <p className="txt-1-r mb-5">Si necesitás más tiempo para completar tu compra, presioná continuar comprando, si no, se cancelará automáticamente.</p>
                    <Row>
                        <Col>
                            <Button color="warning" className="bg-principal text-blanco" block onClick={() => restart()}>Continuar comprando</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    )
}