import React, { forwardRef, useState } from "react";

import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import "../../styles/components/keyboard.scss";

const KeyboardComponent = forwardRef(
  ({ onChange, show, handleHide, type = "complete" }, ref) => {
    const [layout, setLayout] = useState("default");

    const handleShift = () => {
      const newLayoutName = layout === "default" ? "shift" : "default";
      setLayout(newLayoutName);
    };

    const onKeyPress = (button) => {
      if (button === "{capslock}") handleShift();
      if (button === "{escape}") handleHide();
      if (button === "{enter}") handleHide();
    };

    const keyboardSettings = {
      onChange,
      onKeyPress,
      theme: "simple-keyboard hg-theme-default hg-layout-default",
      physicalKeyboardHighlight: true,
      syncInstanceInputs: true,
      mergeDisplay: true,
      debug: true,
      display: {
        "{escape}": "Esc",
        "{bksp}": "⌫",
        "{enter}": "Enter ↵",
        "{capslock}": "⇧ Mayús",
      },
    };

    return (
      <div style={{ display: show ? "block" : "none" }}>
        <div className={"keyboardContainer " + type}>
          {type === "complete" && (
            <Keyboard
              baseClass={"simple-keyboard-main"}
              keyboardRef={(r) => (ref.current = r)}
              layoutName={layout}
              layout={{
                default: [
                  "{escape} ° : ! ¡ $ % & / ( ) = ? ¿ ; {bksp}",
                  " q w e r t y u i o p + * '",
                  '{capslock} a s d f g h j k l ñ ç " {enter}',
                  "< > z x c v b n m _ - .",
                  "@ .com .com.ar {space} @gmail.com @hotmail.com",
                ],
                shift: [
                  "{escape} ° : ! ¡ $ % & / ( ) = ? ¿ ; {bksp}",
                  " Q W E R T Y U I O P + * '",
                  '{capslock} A S D F G H J K L Ñ Ç " {enter}',
                  "< > Z X C V B N M _ - ",
                  ".com @ {space}",
                ],
              }}
              {...keyboardSettings}
            />
          )}

          <div className="numPad">
            <Keyboard
              baseClass={"simple-keyboard-numpad"}
              layout={{
                default: [
                  type === "number" ? "{bksp}" : "{ # }",
                  "{numpad7} {numpad8} {numpad9}",
                  "{numpad4} {numpad5} {numpad6}",
                  "{numpad1} {numpad2} {numpad3}",
                  " {numpad0} ",
                ],
              }}
              {...keyboardSettings}
            />
          </div>
        </div>
      </div>
    );
  }
);

export default KeyboardComponent;
