import * as React from 'react'
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Badge, Button } from 'reactstrap';
import HowItWorks from '../../components/HowItWorks/HowItWorks';
import ilus from '../../assets/ilus.svg';
import ilus2 from '../../assets/ilus-2.svg';
import ilus3 from '../../assets/ilus-3.svg';
import './Start.scss'


function Start(props) {
  return (
    <div className="start d-flex flex-column w-100">
      {/* <Header /> */}
      <Container fluid className="h-100 d-flex flex-column justify-content-center">
        <Row className="w-100">
          <Col xs={{size: 8, offset: 2}}>
            <h1 className="text-center mb-4 text-principal tit-5-b">Dirigirse al mostrador para compra de medicamentos o productos que requieran recetas médicas.</h1>
            <HowItWorks />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(Start);