import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import storeCreator from "./redux/store";
import "./styles/styles.scss";
import App from "./App";
import { UserProvider } from "./context/userContext";
require('dotenv').config()
console.log("env es",process.env)
axios.defaults.baseURL =
  process.env.REACT_APP_STAGE=="QA" && process.env.REACT_APP_ENV=="LOCAL"
        ? "https://qa.totem.delpueblofarmacias.com.ar"
        : `https://totem.delpueblofarmacias.com.ar`;

const store = storeCreator();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <UserProvider>
        <App />
      </UserProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

document.body.addEventListener("click", function () {
  var elem = document.body;

  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.msRequestFullscreen) {
    elem.msRequestFullscreen();
  } else if (elem.mozRequest) {
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    elem.webkitRequestFullscreen();
  } else {
    console.log("Fullscreen API is not supported");
  }
});
