import axios from "axios";
import { getCreditCardNameByNumber } from "creditcard.js";
import history from "../../history";

const EMAIL = "checkout/EMAIL";
const USER = "checkout/USER";
const PROFILE = "checkout/PROFILE";
const PAYMENT = "checkout/PAYMENT";
const MODAL = "checkout/MODAL";

const initialState = {
  email: null,
  firstName: null,
  lastName: null,
  document: null,
  paymentMethod: null,
  paymentIssuer: null,
  cardNumber: null,
  holderName: null,
  dueDate: null,
  validationCode: null,
  installmentOptions: [],
  installments: 1,
  installmentsInterestRate: 0,
  installmentsTotalWithInterest: 0,
  installmentsValueWithInterest: 0,
  user: null,
  processing: false,
  completedPurchase: false,
  isCheckout: false,
  isModal: false,
};

export default function checkout(state = initialState, action) {
  switch (action.type) {
    case EMAIL:
      return {
        ...state,
        ...action.payload,
      };
    case USER:
      return {
        ...state,
        ...action.payload,
      };
    case PROFILE:
      return {
        ...state,
        ...action.payload,
      };
    case PAYMENT:
      return {
        ...state,
        ...action.payload,
      };
    case MODAL:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

const getCardType = (number) => {
  // visa
  var re = new RegExp("^4");
  if (number.match(re) != null) return "Visa";

  // Mastercard
  // Updated for Mastercard 2017 BINs expansion
  if (
    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
      number
    )
  )
    return "Mastercard";

  // AMEX
  re = new RegExp("^3[47]");
  if (number.match(re) != null) return "American Express";

  // Visa Electron
  re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
  if (number.match(re) != null) return "Visa Electron";

  return "";
};

export const getOrSaveEmail =
  (email, isNewsletterOptIn, history) => (dispatch) => {
    axios.get(`/api/checkout/pub/profiles?email=${email}`).then((response) => {
      let firstName = response.data[0] ? response.data[0].firstName : null;
      let lastName = response.data[0] ? response.data[0].lastName : null;
      let document = response.data[0] ? response.data[0].document : null;

      dispatch({
        type: USER,
        payload: {
          email: email,
          firstName: firstName,
          lastName: lastName,
          document: document,
          isNewsletterOptIn: isNewsletterOptIn,
        },
      });
      dispatch({ type: USER, payload: { user: response.data } });

      if (document) {
        history.push("/checkout/register-success");
      } else {
        axios.post(`/api/dataentities/CL/documents`, {
          email: email,
          isNewsletterOptIn: isNewsletterOptIn,
        });
        history.push("/checkout/name");
      }
    });
  };

export const setName = (data) => (dispatch) => {
  dispatch({
    type: PROFILE,
    payload: { firstName: data.firstName, lastName: data.lastName },
  });
};

export const setDNI = (data) => (dispatch) => {
  dispatch({ type: PROFILE, payload: { document: data.dni } });
};

export const setPaymentMethod = (data) => (dispatch) => {
  dispatch({ type: PAYMENT, payload: { paymentMethod: data } });
};

export const setPaymentIssuer = (data) => (dispatch) => {
  dispatch({ type: PAYMENT, payload: { paymentIssuer: data } });
};

export const setPaymentCard = (data) => (dispatch) => {
  dispatch({
    type: PAYMENT,
    payload: {
      cardNumber: data.cardNumber,
      holderName: data.holderName,
      dueDate: data.dueDate,
    },
  });
};

export const setInstallment =
  (
    installments,
    installmentsInterestRate,
    installmentsValueWithInterest,
    installmentsTotalWithInterest
  ) =>
  (dispatch) => {
    dispatch({
      type: PAYMENT,
      payload: {
        installments: parseInt(installments),
        installmentsInterestRate: parseInt(installmentsInterestRate),
        installmentsValueWithInterest: parseInt(installmentsValueWithInterest),
        installmentsTotalWithInterest: parseInt(installmentsTotalWithInterest),
      },
    });
  };

export const setCheckout = () => (dispatch) => {
  dispatch({ type: PAYMENT, payload: { isCheckout: true } });
};

export const setModal = (value) => (dispatch) => {
  dispatch({ type: MODAL, payload: { isModal: value } });
};

export const getInstallmentOptions = () => (dispatch, getState) => {
  const orderForm = getState().cart.orderForm;
  const checkout = getState().checkout;
  console.log("getInstallmentOptions");
  console.log("checkout", checkout);
  console.log("orderForm", orderForm);
  let cardName = checkout?.paymentIssuer;
  orderForm.paymentData.paymentSystems.forEach((item, key) => {
    if (item.name.toLowerCase() === cardName.toLowerCase()) {
      let paymentSystem = item.stringId;

      orderForm.paymentData.installmentOptions.forEach((item, key) => {
        if (item.paymentSystem === paymentSystem) {
          dispatch({
            type: PAYMENT,
            payload: { installmentOptions: item.installments },
          });

          dispatch({
            type: PAYMENT,
            payload: {
              installments: parseInt(item.installments[0].count),
              installmentsInterestRate: parseInt(
                item.installments[0].interestRate
              ),
              installmentsValueWithInterest: parseInt(
                item.installments[0].value
              ),
              installmentsTotalWithInterest: parseInt(
                item.installments[0].total
              ),
            },
          });
        }
      });
    }
  });
};

export const setValidationCodeAndPay =
  (data, callback) => (dispatch, getState) => {
    dispatch({
      type: PAYMENT,
      payload: { validationCode: data, processing: true },
    });

    const orderForm = getState().cart.orderForm;
    const checkout = getState().checkout;
    let orderId = "";
    let cardName = checkout?.paymentIssuer;
    let paymentSystem = null;
    let paymentName = null;
    let value = orderForm.value;
    let group = null;
    let installments =
      checkout.paymentMethod === "credito" ? checkout.installments : 1;
    let installmentsInterestRate =
      checkout.paymentMethod === "credito"
        ? checkout.installmentsInterestRate
        : 0;
    let installmentsValueWithInterest = checkout.installmentsValueWithInterest;
    let installmentsTotalWithInterest =
      checkout.paymentMethod === "credito"
        ? checkout.installmentsTotalWithInterest
        : orderForm.value;
    let shippingCost = 0;

    //console.log("cardName", cardName);

    orderForm.paymentData.paymentSystems.forEach((item, key) => {
      if (checkout.paymentMethod === "debito" && cardName === "Visa")
        cardName = "Visa Electron";
      if (checkout.paymentMethod === "debito" && cardName === "Mastercard")
        cardName = "Maestro";

      if (item.name.toLowerCase() === cardName.toLowerCase()) {
        paymentSystem = item.stringId;
        paymentName = item.name;
        group = item.groupName;
      }
    });

    const clientProfileData =
      checkout.user && checkout.userProfile
        ? checkout.user.userProfile
        : {
            email: checkout.email,
            firstName: checkout.firstName,
            lastName: checkout.lastName,
            document: checkout.document,
            documentType: "dni",
            phone: "+5499999999999",
            corporateName: null,
            tradeName: null,
            corporateDocument: null,
            stateInscription: null,
            corporatePhone: null,
            isCorporate: false,
          };

    let order = {
      items: orderForm.items.map((item) => {
        return {
          id: item?.id,
          requestIndex: item?.requestIndex || 0,
          quantity: item?.quantity,
          seller: item?.seller || 1,
          sellerChain: item?.sellerChain || ["1"],
          tax: item?.tax || 0,
          priceValidUntil: item?.priceValidUntil || "2023-04-14T16:27:35Z",
          price: item?.price,
          listPrice: item?.listPrice,
          rewardValue: item?.rewardValue,
          sellingPrice: item?.sellingPrice,
          offerings: item?.offerings,
          priceTags: item?.priceTags,
          measurementUnit: item?.measurementUnit,
          unitMultiplier: item?.unitMultiplier,
          parentItemIndex: item?.parentItemIndex,
          parentAssemblyBinding: item?.parentAssemblyBinding,
          availability: item?.availability || "withoutStock",
          catalogProvider:
            item?.catalogProvider ||
            "vrn:vtex.catalog-api-proxy:-:farmaciasdelpueblo:master:/proxy/authenticated/catalog/pvt/sku/stockkeepingunitbyid/5705",
          priceDefinition: {
            calculatedSellingPrice:
              item?.priceDefinition?.calculatedSellingPrice,
            total: item?.priceDefinition.total,
            sellingPrices: item?.priceDefinition?.sellingPrices,
          },
        };
      }),
      clientProfileData: clientProfileData,
      shippingData: {
        id: "shippingData",
        address: {
          addressType: "pickup", //residential
          receiverName: checkout.firstName + " " + checkout.lastName,
          postalCode: "1336",
          city: "Yerba Buena",
          state: "Tucumán",
          country: "ARG",
          street: "Avenida Aconquija",
          number: "1336",
          neighborhood: null,
          complement: null,
          reference: null,
          geoCoordinates: [-65.290213, -26.814397],
        },
        logisticsInfo: orderForm?.shippingData?.logisticsInfo,
      },
      paymentData: {
        id: "paymentData",
        payments: [
          {
            paymentSystem: paymentSystem,
            referenceValue: value,
            value: installmentsTotalWithInterest,
            installments: installments,
          },
        ],
      },
    };

    // console.log("request create order", order);
    axios
      .put(
        `/api/checkout/pub/orders?pickupPoint=´${localStorage.getItem(
          "PickupId"
        )}`,
        order
      )
      .then((response) => {
        if (response.status === 400) {
          dispatch({ type: PAYMENT, payload: { processing: false } });
          if (response.data.error && response.data.error.message)
            alert(response.data.error.message);
          else if (
            response.data.orderForm &&
            response.data.orderForm.messages &&
            response.data.orderForm.messages[0] &&
            response.data.orderForm.messages[0].text
          ) {
            alert(response.data.orderForm.messages[0].text);
          } else {
            alert("Error al crear el pedido");
          }
          return false;
        }

        orderId = response.data.orders[0].orderId;
        let orderGroup = response.data.orders[0].orderGroup;
        let transactionId =
          response.data.transactionData.merchantTransactions[0].transactionId;
        let merchantName =
          response.data.transactionData.merchantTransactions[0].merchantName;
        let addressId = response.data.orders[0].shippingData.address.addressId;
        let currencyCode =
          response.data.orders[0].storePreferencesData.currencyCode;

        console.clear();

        let transaction = [
          {
            paymentSystem: parseInt(paymentSystem),
            paymentSystemName: paymentName,
            group: group,
            installments: installments,
            installmentsInterestRate: installmentsInterestRate,
            installmentsValue: installmentsValueWithInterest,
            value: installmentsTotalWithInterest,
            referenceValue: value,
            fields: {
              holderName: checkout.holderName,
              cardNumber: checkout.cardNumber,
              validationCode: checkout.validationCode,
              dueDate: checkout.dueDate,
              addressId: addressId,
            },
            transaction: {
              id: transactionId,
              merchantName: merchantName,
            },
            currencyCode: currencyCode,
          },
        ];

        axios
          .post(
            `/api/pub/transactions/${transactionId}/payments?orderId=${orderId}`,
            transaction
          )
          .then((response) => {
            // console.log(
            //   "response transactions payments",
            //   response.status,
            //   response.data
            // );

            if (response.status !== 201) {
              dispatch({ type: PAYMENT, payload: { processing: false } });
              alert("Error al procesar el pago");
              return false;
            }

            axios
              .post(`/api/checkout/pub/gatewayCallback/${orderGroup}`)
              .then((response) => {
                dispatch({
                  type: PAYMENT,
                  payload: { completedPurchase: true },
                });
                // dispatch({ type: PAYMENT, payload: {processing: false} });

                if (response.status === 500) {
                  dispatch({ type: PAYMENT, payload: { processing: false } });
                  alert(response.data.error.message);
                  return false;
                }

                axios.get(`/api/oms/pvt/orders/${orderId}`).then((response) => {
                  // axios.invoke('save-order-file', orderId, JSON.stringify(response.data, null, 2) )

                  dispatch({ type: PAYMENT, payload: { processing: false } });

                  callback();
                });
              })
              .catch((e) =>
                dispatch({
                  type: MODAL,
                  payload: { isModal: true, processing: false },
                })
              );
          })
          .catch((e) =>
            dispatch({
              type: MODAL,
              payload: { isModal: true, processing: false },
            })
          );
      })
      .catch((e) =>
        dispatch({
          type: MODAL,
          payload: { isModal: true, processing: false },
        })
      );
  };
