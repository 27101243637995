import axios from "axios";
import history from "../../history";

const ORDER_FORM = "cart/ORDER_FORM";
const LOADING = "cart/LOADING";
const SHOW = "cart/SHOW";
const PRODUCT_NOT_FOUND = "cart/PRODUCT_NOT_FOUND";
const MESSAGE = "cart/MESSAGE";

const initialState = {
  orderForm: null,
  loading: false,
  show: true,
  productNotFound: null,
  message: "",
};

export default function cart(state = initialState, action) {
  switch (action.type) {
    case ORDER_FORM:
      return {
        ...state,
        ...action.payload,
      };
    case LOADING:
      return {
        ...state,
        ...action.payload,
      };
    case SHOW:
      return {
        ...state,
        ...action.payload,
      };
    case PRODUCT_NOT_FOUND:
      return {
        ...state,
        ...action.payload,
      };
    case MESSAGE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export const orderForm = (id) => (dispatch) => {
  let sucursalID = localStorage.getItem("PickupId") || id || 15;
  axios.post(`/api/checkout/pub/orderForm`).then((response) => {
    if (response.status === 200) {
      axios
        .post(
          `/api/checkout/pub/orderForm/${response.data.orderFormId}/attachments/shippingData?pickupPointId=${sucursalID}`
        )
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ORDER_FORM,
              payload: { orderForm: response.data },
            });
          }
        });
    }
  });
};

export const addItem =
  (id, quantity, seller = 1, callback = null) =>
  (dispatch, getState) => {
    const orderForm = getState().cart.orderForm;
    dispatch({ type: LOADING, payload: { loading: true } });

    // axios.post(`/api/checkout/pub/orderForm/${orderForm.orderFormId}`)
    // 	.then((response) => {
    // console.log('addItem', response)
    let isUpdate = false;
    if (orderForm.items) {
      orderForm.items.forEach((item, index) => {
        if (parseInt(item.id) === parseInt(id)) {
          isUpdate = true;
          let qtd = quantity + item.quantity;
          _updateItem(index, qtd, dispatch, getState);
          return;
        }
      });
    }

    axios
      .post(`/api/checkout/pub/orderForm/${orderForm.orderFormId}/items`, {
        orderItems: [
          {
            id: id,
            quantity: quantity,
            seller: seller,
          },
        ],
      })
      .then((response) => {
        dispatch({ type: ORDER_FORM, payload: { orderForm: response.data } });
        dispatch({ type: LOADING, payload: { loading: false } });
        if (callback) callback();
      });
    // })
  };

const _updateItem = (index, quantity, dispatch, getState, callback = null) => {
  const orderForm = getState().cart.orderForm;

  // axios.post(`/api/checkout/pub/orderForm/${orderForm.orderFormId}`)
  // 	.then((response) => {
  // 		axios.post(`/api/checkout/pub/orderForm/${orderForm.orderFormId}/items/update/`, {
  // 				orderItems:[
  // 					{
  // 						index: index,
  // 						quantity: quantity
  // 					}
  // 				]
  // 			})
  // 			.then((response) => {
  // 				dispatch({ type: ORDER_FORM, payload: {orderForm: response.data} });
  // 				if(response.data.items.length === 0) history.push('/cart')
  // 				if(callback) callback()
  // 			})
  // 	})

  orderForm.items.forEach((item, key) => {
    if (key === index) item.quantity = quantity;
  });
  dispatch({ type: ORDER_FORM, payload: { orderForm: orderForm } });

  axios
    .post(
      `/api/checkout/pub/orderForm/${orderForm.orderFormId}/items/update/`,
      {
        orderItems: [
          {
            index: index,
            quantity: quantity,
          },
        ],
      }
    )
    .then((response) => {
      // response.data.items.forEach((item, key) => {
      // 	if(key === index) {
      // 		let qtd = item.quantity
      // 		if(item.quantity < quantity) dispatch({ type: ORDER_FORM, payload: {message: `No tiene ${quantity} unidades disponibles en stock.`} });
      // 	}
      // })

      dispatch({ type: ORDER_FORM, payload: { orderForm: response.data } });
      if (response.data.items.length === 0) history.push("/cart");
      if (callback) callback();
    });
};

export const updateItem =
  (index, quantity, callback = null) =>
  (dispatch, getState) => {
    _updateItem(index, quantity, dispatch, getState, () => {
      if (callback) callback();
    });
  };

export const showCart =
  (show = true) =>
  (dispatch) => {
    dispatch({ type: SHOW, payload: { show: show } });
  };

export const setProductNotFound = (productNotFound) => (dispatch) => {
  dispatch({
    type: PRODUCT_NOT_FOUND,
    payload: { productNotFound: productNotFound },
  });
};

export const setMessage = (msg) => (dispatch) => {
  dispatch({ type: MESSAGE, payload: { message: msg } });
};
