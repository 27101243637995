import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Input, FormGroup, FormFeedback, Row, Col } from "reactstrap";
import { useHistory, withRouter } from "react-router-dom";
import {
  setValidationCodeAndPay,
  getInstallmentOptions,
  setInstallment,
  setModal,
} from "../../redux/modules/checkout";
import { showCart } from "../../redux/modules/cart";
import "./Checkout.scss";
import { useOutside } from "../../hooks/useOutside";
import KeyboardComponent from "../../components/Keyboard/KeyboardComponent";
import ModalModels from "../../components/Modal/ModalModels";

function PaymentCVV(props) {
  const { orderForm } = props.cart;
  const { installmentOptions, processing, paymentMethod, isModal } =
    props.checkout;
  const keyboard = React.useRef(null);
  const keyboardContainer = React.useRef(null);

  const { show, handleShow, handleHide } = useOutside(keyboardContainer);

  const [inputValidationCode, setInputValidationCode] = React.useState("");
  const [validationError, setValidationError] = React.useState("");
  const [installmentsError, setInstallmentsError] = React.useState("");

  const history = useHistory();

  const handleChange = ({ target }) => {
    setInputValidationCode(target.value);
    keyboard.current.setInput(target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputValidationCode.length === 3) {
      props.setValidationCodeAndPay(inputValidationCode, () => {
        props.showCart(false);
        props.history.push("/checkout/success");
      });
    } else {
      setValidationError("Ingrese un código válido");
    }
  };
  const CheckedValueCredito = (installment) => {
    let htmlCheck = "";
    if (installment.count === 1) {
      htmlCheck = `Total - $ ${installment.total / 100}`;
    } else if (installment.count > 1 && installment.interestRate === 0) {
      htmlCheck = `${installment.count} cuotas de $ ${
        installment.value / 100
      } sin interés`;
    } else {
      htmlCheck = `${installment.count} cuotas de $ ${
        installment.value / 100
      } con interés de ${installment.interestRate / 100}% mensual`;
    }
    return htmlCheck;
  };
  const CheckedValueDebito = (installment) => {
    let htmlCheck = "";
    if (installment.count === 1) {
      htmlCheck = `Total - $ ${installment.total / 100}`;
    }
    return htmlCheck;
  };
  const historyGoPayment = () => {
    props.setModal(false);
    history.push("/checkout/payment-method");
  };

  const accept = () => props.setModal(false);
  React.useEffect(() => {
    if (orderForm) props.getInstallmentOptions();
  }, []);
  return (
    <>
      <Col className="d-flex flex-column">
        <Row className="align-items-center h-100">
          <Col
            sm={{ size: 10, offset: 2 }}
            className="d-flex flex-column"
            style={{ marginLeft: "14.9%" }}
          >
            <h3 className="h2-r mb-5">
              Ingresá los siguientes datos de tu tarjeta
            </h3>

            <form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <div ref={keyboardContainer} onClick={handleShow}>
                    <FormGroup>
                      <Input
                        type="number"
                        name="validationCode"
                        onKeyUp={handleChange}
                        onChange={handleChange}
                        value={inputValidationCode}
                        invalid={validationError}
                        placeholder="Código de seguridad"
                      />
                      <FormFeedback>{validationError}</FormFeedback>
                    </FormGroup>
                    <KeyboardComponent
                      ref={keyboard}
                      show={show}
                      onChange={setInputValidationCode}
                      handleHide={handleHide}
                      type="number"
                    />
                  </div>
                </Col>
                <Col>
                  {paymentMethod === "credito" ? (
                    <FormGroup>
                      <Input
                        type="select"
                        name="installments"
                        onChange={(e) =>
                          props.setInstallment(
                            e.target.value,
                            e.target.selectedOptions[0].getAttribute(
                              "interestRate"
                            ),
                            e.target.selectedOptions[0].getAttribute(
                              "installmentsValueWithInterest"
                            ),
                            e.target.selectedOptions[0].getAttribute(
                              "installmentstotalwithinterest"
                            )
                          )
                        }
                        value={props.checkout.installments}
                        invalid={installmentsError}
                        placeholder="Apellido"
                      >
                        {installmentOptions.map((installment) => {
                          return (
                            <option
                              value={installment.count}
                              interestRate={installment.interestRate}
                              installmentsValueWithInterest={installment.value}
                              installmentsTotalWithInterest={installment.total}
                            >
                              {/* {installment.count === 1
                              ? `Total - $ ${installment.total / 100}`
                              : `${installment.count} cuotas de $ ${
                                  installment.value / 100
                                } con interés de ${
                                  installment.interestRate / 100
                                }% mensual`} */}
                              {CheckedValueCredito(installment)}
                            </option>
                          );
                        })}
                      </Input>
                      <FormFeedback>{installmentsError}</FormFeedback>
                    </FormGroup>
                  ) : (
                    <FormGroup>
                      <Input
                        type="select"
                        name="installments"
                        onChange={(e) =>
                          props.setInstallment(
                            e.target.value,
                            e.target.selectedOptions[0].getAttribute(
                              "interestRate"
                            ),
                            e.target.selectedOptions[0].getAttribute(
                              "installmentsValueWithInterest"
                            ),
                            e.target.selectedOptions[0].getAttribute(
                              "installmentstotalwithinterest"
                            )
                          )
                        }
                        value={props.checkout.installments}
                        invalid={installmentsError}
                        placeholder="Apellido"
                      >
                        {installmentOptions.map((installment) => {
                          return (
                            <option
                              value={installment.count}
                              interestRate={installment.interestRate}
                              installmentsValueWithInterest={installment.value}
                              installmentsTotalWithInterest={installment.total}
                            >
                              {CheckedValueDebito(installment)}
                            </option>
                          );
                        })}
                      </Input>
                      <FormFeedback>{installmentsError}</FormFeedback>
                    </FormGroup>
                  )}
                </Col>
              </Row>

              <div className="d-flex align-items-center mt-5">
                <div className="t1-alt-b text-g6">
                  <span className="text-principal">3</span>/3
                </div>
                <div className="ml-auto">
                  <Button
                    color="link"
                    className="btn-3 text-g6"
                    style={{ width: 190 }}
                    disabled={processing}
                    onClick={() => props.history.goBack()}
                  >
                    Volver
                  </Button>
                  <Button
                    type="submit"
                    color="warning"
                    style={{ width: 190 }}
                    disabled={processing}
                    className="bg-principal border-principal text-blanco"
                  >
                    {!processing ? "Siguiente" : "Procesando..."}
                  </Button>
                </div>
              </div>
            </form>
          </Col>
        </Row>
        {/* {isModal === true && (
          <div className="backdrop">
            <ModalModels
              historyGoPayment={historyGoPayment}
              isModal={isModal}
            />
          </div>
        )} */}
        <ModalModels
          visible={isModal}
          title="Error de verificación"
          onClickButton={accept}
          historyGoPayment={historyGoPayment}
        >
          <div className="cart-modal-content">
            Su compra no se ha finalizado debido a algún problema en la
            autorización del pago. Es posible que haya llenado algunos datos de
            forma incorrecta o puede haber habido algún problema con la tarjeta.
          </div>
        </ModalModels>
      </Col>
    </>
  );
}

function mapStateToProps(state) {
  const { cart, checkout } = state;
  return {
    cart,
    checkout,
  };
}

function mapDistpachToProps(dispatch) {
  return bindActionCreators(
    {
      setValidationCodeAndPay,
      getInstallmentOptions,
      setInstallment,
      showCart,
      setModal,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDistpachToProps
)(withRouter(PaymentCVV));
