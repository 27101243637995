import * as React from 'react'
import { Container, Row, Col, Card, CardBody, Badge, Button } from 'reactstrap';
import Header from '../../components/Header/Header';
import ilus from '../../assets/ilus.svg';
import ilus2 from '../../assets/ilus-2.svg';
import ilus3 from '../../assets/ilus-3.svg';
import history from '../../history';
import './HowItWorks.scss'


function HowItWorks(props) {
    return (
        <div className="how-it-works">
            <Row>
                <Col xs={{size: 4}}>
                <Card className="h-100">
                    <CardBody className="d-flex justify-content-center">
                    <div className="w-100">
                        <Badge className="mb-2">1</Badge>
                        <div className="text-center d-flex flex-column align-items-center">
                        <img src={ilus} className="d-block mb-3 card-img" width={180}/>
                        <h5 className="tit-5-b text-g9">Escaneá tus productos</h5>
                        </div>
                    </div>
                    </CardBody>
                </Card>
                </Col>
                <Col xs={{size: 4}}>
                <Card className="h-100">
                    <CardBody className="d-flex justify-content-center">
                    <div className="w-100">
                        <Badge className="mb-2">2</Badge>
                        <div className="text-center d-flex flex-column align-items-center">
                        <img src={ilus2} className="d-block mb-3 card-img" width={180}/>
                        <h5 className="tit-5-b text-g9">Pagá con tu tarjeta</h5>
                        </div>
                    </div>
                    </CardBody>
                </Card>
                </Col>
                <Col xs={{size: 4}}>
                <Card className="h-100">
                    <CardBody className="d-flex justify-content-center">
                    <div className="w-100">
                        <Badge className="mb-2">3</Badge>
                        <div className="text-center d-flex flex-column align-items-center">
                        <img src={ilus3} className="d-block mb-3 card-img" width={180}/>
                        <h5 className="tit-5-b text-g9">Mostrá tu ticket antes de salir</h5>
                        </div>
                    </div>
                    </CardBody>
                </Card>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                <Card className="card-bottom h-100 bg-blanco border border-g2">
                    <CardBody className="d-flex justify-content-center align-items-center">
                    <div className="text-center">
                        <h5 className="txt-1-b text-g8">Recordá que no podrás abonar compras con receta por este medio</h5>
                        <p className="txt-2-r text-g8 m-0">Dirigite a la caja para compra de medicamentos o productos que requieran recetas médicas.</p>
                    </div>
                    </CardBody>
                </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    {
                        !props.modal ?
                        <Button color="warning" block className={`mt-4 btn-start btn-1 text-blanco`} onClick={() => history.push('/cart')}>Empezar</Button>
                        :
                        <Button color="success" block className={`mt-4 btn-start-success btn-1 text-blanco`} onClick={() => props.onClose()}>Volver</Button>
                    }
                </Col>
            </Row>
        </div>
    );
};

export default HowItWorks;