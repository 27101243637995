import { useEffect, useState, useCallback } from "react";

export const useOutside = (ref) => {
  const [show, setShow] = useState(false);

  const handleShow = useCallback(() => setShow(true), []);
  const handleHide = useCallback(() => setShow(false), []);

  useEffect(() => {
    const listener = (event) => {
      if (!ref?.current || ref.current.contains(event.target)) {
        return;
      }
      setShow(false);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener, { passive: true });
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener, { passive: true });
    };
  }, [ref]);

  return { show, handleShow, handleHide };
};
