import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Container,
  Button,
  Input,
  Label,
  FormGroup,
  FormFeedback,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import {
  setPaymentMethod,
  setPaymentIssuer,
} from "../../redux/modules/checkout";
import "./Checkout.scss";

function PaymentMethod(props) {
  const { paymentMethod, paymentIssuer } = props.checkout;

  return (
    <Col className="d-flex flex-column">
      <Row className="align-items-center h-100">
        <Col
          sm={{ size: 10, offset: 2 }}
          className="d-flex flex-column"
          style={{ marginLeft: "14.9%" }}
        >
          <h3 className="h2-r mb-5">Seleccioná tu método de pago</h3>
          <Row>
            <Col>
              <Card
                className="text-center"
                style={{
                  borderColor: paymentMethod === "debito" ? "#7DC832" : null,
                }}
                onClick={() => props.setPaymentMethod("debito")}
              >
                <CardBody className="pt-4 pb-4">
                  <svg
                    style={{ width: "7.613vw" }}
                    viewBox="0 0 145 144"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M124.75 100V44C124.75 41.8783 123.907 39.8434 122.407 38.3431C120.907 36.8429 118.872 36 116.75 36H28.75C26.6283 36 24.5934 36.8429 23.0931 38.3431C21.5929 39.8434 20.75 41.8783 20.75 44V100C20.75 102.122 21.5929 104.157 23.0931 105.657C24.5934 107.157 26.6283 108 28.75 108H116.75C118.872 108 120.907 107.157 122.407 105.657C123.907 104.157 124.75 102.122 124.75 100ZM28.75 44H116.75V100H28.75V44Z"
                      fill={paymentMethod === "debito" ? "#7DC832" : "#C8C8C8"}
                    />
                    <path
                      d="M100.75 72H36.75C35.6891 72 34.6717 72.4214 33.9216 73.1716C33.1714 73.9217 32.75 74.9391 32.75 76C32.75 77.0609 33.1714 78.0783 33.9216 78.8284C34.6717 79.5786 35.6891 80 36.75 80H100.75C101.811 80 102.828 79.5786 103.578 78.8284C104.329 78.0783 104.75 77.0609 104.75 76C104.75 74.9391 104.329 73.9217 103.578 73.1716C102.828 72.4214 101.811 72 100.75 72Z"
                      fill={paymentMethod === "debito" ? "#7DC832" : "#C8C8C8"}
                    />
                    <path
                      d="M88.75 84H36.75C35.6891 84 34.6717 84.4214 33.9216 85.1716C33.1714 85.9217 32.75 86.9391 32.75 88C32.75 89.0609 33.1714 90.0783 33.9216 90.8284C34.6717 91.5786 35.6891 92 36.75 92H88.75C89.8109 92 90.8283 91.5786 91.5784 90.8284C92.3286 90.0783 92.75 89.0609 92.75 88C92.75 86.9391 92.3286 85.9217 91.5784 85.1716C90.8283 84.4214 89.8109 84 88.75 84Z"
                      fill={paymentMethod === "debito" ? "#7DC832" : "#C8C8C8"}
                    />
                    <path
                      d="M44.75 48H36.75C34.5409 48 32.75 49.7909 32.75 52V60C32.75 62.2091 34.5409 64 36.75 64H44.75C46.9591 64 48.75 62.2091 48.75 60V52C48.75 49.7909 46.9591 48 44.75 48Z"
                      fill={paymentMethod === "debito" ? "#7DC832" : "#C8C8C8"}
                    />
                  </svg>
                  <h4 className="h4-b">Débito</h4>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card
                className="text-center"
                style={{
                  borderColor: paymentMethod === "credito" ? "#7DC832" : null,
                }}
                onClick={() => props.setPaymentMethod("credito")}
              >
                <CardBody className="pt-4 pb-4">
                  <svg
                    style={{ width: "7.613vw" }}
                    viewBox="0 0 145 144"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M116.25 36H28.25C26.1283 36 24.0934 36.8429 22.5931 38.3431C21.0929 39.8434 20.25 41.8783 20.25 44V100C20.25 102.122 21.0929 104.157 22.5931 105.657C24.0934 107.157 26.1283 108 28.25 108H116.25C118.372 108 120.407 107.157 121.907 105.657C123.407 104.157 124.25 102.122 124.25 100V44C124.25 41.8783 123.407 39.8434 121.907 38.3431C120.407 36.8429 118.372 36 116.25 36ZM116.25 100H28.25V56H116.25V100Z"
                      fill={paymentMethod === "credito" ? "#7DC832" : "#C8C8C8"}
                    />
                    <path
                      d="M36.25 74H76.25C77.3109 74 78.3283 73.5786 79.0784 72.8284C79.8286 72.0783 80.25 71.0609 80.25 70C80.25 68.9391 79.8286 67.9217 79.0784 67.1716C78.3283 66.4214 77.3109 66 76.25 66H36.25C35.1891 66 34.1717 66.4214 33.4216 67.1716C32.6714 67.9217 32.25 68.9391 32.25 70C32.25 71.0609 32.6714 72.0783 33.4216 72.8284C34.1717 73.5786 35.1891 74 36.25 74Z"
                      fill={paymentMethod === "credito" ? "#7DC832" : "#C8C8C8"}
                    />
                    <path
                      d="M64.25 82H36.25C35.1891 82 34.1717 82.4214 33.4216 83.1716C32.6714 83.9217 32.25 84.9391 32.25 86C32.25 87.0609 32.6714 88.0783 33.4216 88.8284C34.1717 89.5786 35.1891 90 36.25 90H64.25C65.3109 90 66.3283 89.5786 67.0784 88.8284C67.8286 88.0783 68.25 87.0609 68.25 86C68.25 84.9391 67.8286 83.9217 67.0784 83.1716C66.3283 82.4214 65.3109 82 64.25 82Z"
                      fill={paymentMethod === "credito" ? "#7DC832" : "#C8C8C8"}
                    />
                    <path
                      d="M104.25 66H96.25C94.0409 66 92.25 67.7909 92.25 70V78C92.25 80.2091 94.0409 82 96.25 82H104.25C106.459 82 108.25 80.2091 108.25 78V70C108.25 67.7909 106.459 66 104.25 66Z"
                      fill={paymentMethod === "credito" ? "#7DC832" : "#C8C8C8"}
                    />
                  </svg>
                  <h4 className="h4-b">Crédito</h4>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {paymentMethod === "debito" ? (
            <>
              <h3 className="h2-r mb-5">Seleccioná el emisor</h3>
              <div
                className="cardsContainer"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                  marginTop: "5%",
                  maxWidth: "500px",
                }}
              >
                <div
                  onClick={() => props.setPaymentIssuer("Visa")}
                  style={{
                    margin: "1%",
                    width: "100px",
                    height: "100px",
                    border: `3px solid ${
                      paymentIssuer === "Visa" ? "#7DC832" : "gray"
                    }`,
                    borderRadius: "10%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="https://farmaciasdelpueblo.vteximg.com.br/arquivos/checkout-visa.png"
                    style={{
                      width: "60px",
                      height: "60px",
                      objectFit: "contain",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    alt="VISA LOGO"
                  ></img>
                  <h6>VISA</h6>
                </div>

                <div
                  onClick={() => props.setPaymentIssuer("Maestro")}
                  style={{
                    margin: "1%",
                    width: "100px",
                    height: "100px",
                    border: `3px solid ${
                      paymentIssuer === "Maestro" ? "#7DC832" : "gray"
                    }`,
                    borderRadius: "10%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="https://farmaciasdelpueblo.vteximg.com.br/arquivos/checkout-maestro.png"
                    style={{
                      width: "60px",
                      height: "60px",
                      objectFit: "contain",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    alt="VISA LOGO"
                  ></img>
                  <h6>MAESTRO</h6>
                </div>
              </div>
            </>
          ) : null}

          {paymentMethod === "credito" ? (
            <>
              <h3 className="h2-r mb-5">Seleccioná el emisor</h3>
              <div
                className="cardsContainer"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                  marginTop: "5%",
                  maxWidth: "700px",
                }}
              >
                <div
                  onClick={() => props.setPaymentIssuer("Visa")}
                  style={{
                    margin: "1%",
                    width: "100px",
                    height: "100px",
                    border: `3px solid ${
                      paymentIssuer === "Visa" ? "#7DC832" : "gray"
                    }`,
                    borderRadius: "10%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="https://farmaciasdelpueblo.vteximg.com.br/arquivos/checkout-visa.png"
                    style={{
                      width: "60px",
                      height: "60px",
                      objectFit: "contain",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    alt="VISA LOGO"
                  ></img>
                  <h6>VISA</h6>
                </div>

                <div
                  onClick={() => props.setPaymentIssuer("Mastercard")}
                  style={{
                    margin: "1%",
                    width: "100px",
                    height: "100px",
                    border: `3px solid ${
                      paymentIssuer === "Mastercard" ? "#7DC832" : "gray"
                    }`,
                    borderRadius: "10%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="https://farmaciasdelpueblo.vteximg.com.br/arquivos/checkout-mastercard.png"
                    style={{
                      width: "60px",
                      height: "60px",
                      objectFit: "contain",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    alt="MASTERCARD LOGO"
                  ></img>
                  <h6>MASTER</h6>
                </div>

                <div
                  onClick={() => props.setPaymentIssuer("American Express")}
                  style={{
                    margin: "1%",
                    width: "100px",
                    height: "100px",
                    border: `3px solid ${
                      paymentIssuer === "American Express" ? "#7DC832" : "gray"
                    }`,
                    borderRadius: "10%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="https://farmaciasdelpueblo.vteximg.com.br/arquivos/checkout-amex.png"
                    style={{
                      width: "60px",
                      height: "60px",
                      objectFit: "contain",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    alt="AMEX LOGO"
                  ></img>
                  <h6>AMEX</h6>
                </div>

                <div
                  onClick={() => props.setPaymentIssuer("Naranja")}
                  style={{
                    margin: "1%",
                    width: "100px",
                    height: "100px",
                    border: `3px solid ${
                      paymentIssuer === "Naranja" ? "#7DC832" : "gray"
                    }`,
                    borderRadius: "10%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="https://farmaciasdelpueblo.vteximg.com.br/arquivos/checkout-naranja.png"
                    style={{
                      width: "60px",
                      height: "60px",
                      objectFit: "contain",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    alt="Naranja LOGO"
                  ></img>
                  <h6>Naranja</h6>
                </div>

                <div
                  onClick={() => props.setPaymentIssuer("Cabal")}
                  style={{
                    margin: "1%",
                    width: "100px",
                    height: "100px",
                    border: `3px solid ${
                      paymentIssuer === "Cabal" ? "#7DC832" : "gray"
                    }`,
                    borderRadius: "10%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="https://farmaciasdelpueblo.vteximg.com.br/arquivos/checkout-cabal.png"
                    style={{
                      width: "60px",
                      height: "60px",
                      objectFit: "contain",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    alt="CABAL LOGO"
                  ></img>
                  <h6>Cabal</h6>
                </div>
              </div>
            </>
          ) : null}

          <div className="d-flex align-items-center mt-5">
            <div className="t1-alt-b text-g6">
              <span className="text-principal">1</span>/3
            </div>
            <div className="ml-auto">
              <Button
                color="link"
                className="btn-3 text-g6"
                style={{ width: 190 }}
                onClick={() => props.history.push("/checkout/dni")}
              >
                Volver
              </Button>
              <Button
                color={paymentMethod && paymentIssuer ? "warning" : "secondary"}
                style={{ width: 190 }}
                disabled={paymentMethod && paymentIssuer ? false : true}
                className="bg-principal border-principal text-blanco"
                onClick={() => props.history.push("/checkout/payment-card")}
              >
                Siguiente
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Col>
  );
}

function mapStateToProps(state) {
  const { checkout } = state;
  return {
    checkout,
  };
}

function mapDistpachToProps(dispatch) {
  return bindActionCreators(
    {
      setPaymentMethod,
      setPaymentIssuer,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDistpachToProps
)(withRouter(PaymentMethod));
