import React, { useState, useRef } from "react";

import KeyboardWrapper from "../../components/Keyboard/KeyboardWrapper";

import "../../styles/login/login.scss";
import logo from "../../assets/logo.svg";
import { useLogin } from "../../hooks/useLogin";
import { useHistory } from "react-router-dom";

const Login = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { handleLogin } = useLogin();

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin({ email, password }).then((res) => {
      if (res.status === 200) {
        history.push("/login/sucursales");
      } else {
        setError("Usuario o contraseña incorrectos");
      }
    });
  };

  return (
    <div className="login">
      <form onSubmit={handleSubmit}>
        <img
          src={logo}
          height={189}
          width={324}
          alt="logo farmacias del pueblo"
        />
        <div className="inputs">
          <KeyboardWrapper
            value={email}
            setValue={setEmail}
            otherInputsProps={{
              type: "text",
              placeholder: "Email",
              name: "Email",
            }}
          />
          <KeyboardWrapper
            value={password}
            setValue={setPassword}
            otherInputsProps={{
              type: "password",
              placeholder: "Contraseña",
              name: "password",
            }}
          />
        </div>
        <button type="submit">Ingresar</button>

        {error ? (
          <span className="d-block t1-r text-alerta mb-2">{error}</span>
        ) : null}
      </form>
    </div>
  );
};

export default Login;
