import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Container,
  Button,
  Input,
  Label,
  FormGroup,
  FormFeedback,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { setPaymentCard } from "../../redux/modules/checkout";
import deslizarTarjeta from "../../assets/deslizar.gif";
import "./Checkout.scss";

var interval;

function PaymentCard(props) {
  const [focus, setFocus] = React.useState(true);
  const cardInputRef = React.useRef(null);

  React.useEffect(() => {
    let lastData = "";
    cardInputRef.current.focus();

    setInterval(() => {
      if (cardInputRef.current || localStorage.getItem("cardNumber"))
        if (
          (lastData.length > 0 &&
            lastData === cardInputRef.current.value &&
            lastData.slice(-1) === "_") ||
          localStorage.getItem("cardNumber")
        ) {
          try {
            let cardData = lastData.length
              ? getCardData(lastData)
              : getFromLocal();
            console.log("por setear", cardData);
            props.setPaymentCard(cardData);
            props.history.push("/checkout/payment-cvv");
          } catch (err) {
            //pass
          }
        } else {
          lastData = cardInputRef.current.value;
        }
    }, 1000);
  }, []);

  const getFromLocal = () => {
    return {
      cardNumber: localStorage.getItem("cardNumber"),
      holderName: localStorage.getItem("holderName"),
      dueDate: localStorage.getItem("dueDate"),
    };
  };

  const getCardData = (str) => {
    cardInputRef.current.value = "";
    return {
      cardNumber: str.match(/(?<=%B)(.+?)(?=&)/)[0],
      holderName: str.match(/(?<=&)(.+?)(?=&)/)[0],
      dueDate:
        str.match(/(?<=%B(.+?)&(.+?)&([0-9]{2}))([0-9]{2})/)[0] +
        "/" +
        str.match(/(?<=%B(.+?)&(.+?)&)([0-9]{2})/)[0],
    };
  };

  const goBack = () => {
    setFocus(false);
    props.history.goBack();
  };

  return (
    <Col className="d-flex flex-column">
      <Row className="align-items-center h-100">
        <Col
          sm={{ size: 10, offset: 2 }}
          className="d-flex flex-column"
          style={{ marginLeft: "14.9%" }}
        >
          <h3 className="h2-r mb-5">Deslizá tu tarjeta para pagar</h3>
          <input
            type="text"
            ref={cardInputRef}
            onBlur={() => (focus ? cardInputRef.current.focus() : null)}
            style={{ position: "absolute", opacity: 0, left: -9999999 }}
          />
          <div className="d-flex justify-content-center">
            <img src={deslizarTarjeta} style={{ width: "28.551vw" }} />
          </div>

          <div className="d-flex align-items-center mt-5">
            <div className="t1-alt-b text-g6">
              <span className="text-principal">2</span>/3
            </div>
            <div className="ml-auto">
              <Button
                color="link"
                className="btn-3 text-g6"
                style={{ width: 190 }}
                onClick={() => goBack()}
              >
                Volver
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Col>
  );
}

function mapStateToProps(state) {
  const { checkout } = state;
  return {
    checkout,
  };
}

function mapDistpachToProps(dispatch) {
  return bindActionCreators(
    {
      setPaymentCard,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDistpachToProps
)(withRouter(PaymentCard));
