import React from "react";
// import "./Modal.css";

const ModalModels = ({
  visible,
  title,
  children,
  onClickButton,
  historyGoPayment,
}) => {
  return (
    visible && (
      <div className="backdrop">
        <div className=" my-card">
          <div className="modal-title-content">
            <span className="modal-title">{title}</span>
          </div>
          {children}
          <br />
          <button className="validateDate" onClick={onClickButton}>
            Verifique los datos
          </button>
          <button className="selectPago" onClick={historyGoPayment}>
            seleccione otra forma de pago
          </button>
        </div>
      </div>
    )
  );
};

export default ModalModels;
