import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, InputGroup, InputGroupAddon, Input, Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import Slider from "react-slick";
import {
  showCart,
  addItem,
  setProductNotFound,
} from "../../redux/modules/cart";
import { search, resetItems } from "../../redux/modules/products";
import history from "../../history";
import iconSearch from "../../assets/search.svg";
import iconCart from "../../assets/icon-cart.svg";
import spinner from "../../assets/spinner.svg";
import back from "../../assets/back.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Cart.scss";
import { useOutside } from "../../hooks/useOutside";
import KeyboardComponent from "../../components/Keyboard/KeyboardComponent";

function Search(props) {
  const [value, setValue] = React.useState("");

  const keyboard = React.useRef(null);
  const keyboardContainer = React.useRef(null);

  const {
    show: showKeyboard,
    handleShow,
    handleHide,
  } = useOutside(keyboardContainer);

  const { show, orderForm } = props.cart;
  const { items, loading } = props.products;

  const handleChange = ({ target }) => {
    setValue(target.value);
    keyboard.current.setInput(target.value);
  };

  const addToCard = (skuId) => {
    props.addItem(skuId, 1, 1, function (res) {
      props.showCart(true);
      props.resetItems();
      history.push("/cart");
    });
  };

  const imageResize = (url) => {
    let imageId = url.split("/ids/")[1].split("/")[0];
    return url.replace(`/ids/${imageId}/`, `/ids/${imageId}-300-300/`);
  };

  const searchProducts = () => {
    props.search(value, function (items) {
      props.showCart(true);
      if (items.length === 0) {
        props.setProductNotFound(value);
        history.push("/cart");
      } else {
        props.setProductNotFound(null);
      }
    });
  };

  const reset = () => {
    props.resetItems();
    props.setProductNotFound(null);
    props.showCart(false);
  };

  React.useEffect(() => {
    reset();
  }, []);

  return (
    <>
      <div
        className="text-uppercase btn-3 text-secundario d-flex align-items-center"
        style={{
          position: "absolute",
          top: "6vw",
          left: "3.5vw",
          zIndex: 9999,
          padding: "0.732vw",
        }}
        onClick={() => (!items ? props.history.push("/cart") : reset())}
      >
        <img src={back} style={{ width: "0.878vw", marginRight: "0.878vw" }} />{" "}
        Volver
      </div>
      {!loading ? (
        !items ? (
          <Col
            className="d-flex flex-column align-items-center"
            style={{ paddingTop: "8.2vw" }}
          >
            <h2 className="h1-r mb-4 pb-3">¿Qué producto buscás?</h2>
            <div className="buscador" ref={keyboardContainer} onClick={handleShow}>
              <InputGroup className="cart-input-search">
                <InputGroupAddon addonType="prepend">
                  <span className="input-group-text">
                    <img src={iconSearch} style={{ width: "1.464vw" }} />
                  </span>
                </InputGroupAddon>
                <Input
                  className="tit-5-r text-g5"
                  placeholder="Buscar"
                  type="text"
                  onChange={handleChange}
                  value={value}
                />
                <InputGroupAddon addonType="append">
                  <Button
                    color="secondary"
                    className="btn-2"
                    onClick={() => searchProducts()}
                  >
                    Buscar
                  </Button>
                </InputGroupAddon>
              </InputGroup>

                <KeyboardComponent
                  ref={keyboard}
                  show={showKeyboard}
                  onChange={setValue}
                  handleHide={handleHide}
                />

            </div>
          </Col>
        ) : (
          <Col
            className="pt-5 search"
            xs={{
              size:
                orderForm && orderForm.items && orderForm.items.length ? 6 : 10,
              offset: 1,
            }}
          >
            <h3 className="btn-1 text-negro">
              Resultados{" "}
              <span className="btn-1 text-secundario ml-5">“{value}”</span>
            </h3>
            <p className="h4-r" style={{ marginBottom: "4.246vw" }}>
              Seleccioná el producto que querés sumar al carrito
            </p>
            <div>
              <Slider
                dots={false}
                arrows={true}
                infinite={false}
                speed={500}
                slidesToShow={
                  orderForm && orderForm.items && orderForm.items.length ? 3 : 5
                }
                slidesToScroll={
                  orderForm && orderForm.items && orderForm.items.length ? 3 : 5
                }
              >
                {items.map((item, key) => {
                  return (
                    <div key={key} className="card">
                      <div className="card-body">
                        <img
                          src={imageResize(item.items[0].images[0].imageUrl)}
                          className="search-product-image"
                          alt="search"
                        />
                        <h4 className="t3">
                          {item.categories[0].split("/")[1]}
                        </h4>
                        <h5 className="t2-r">{item.productName}</h5>
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <div>
                            {item.items[0].sellers[0].commertialOffer.Price <
                            item.items[0].sellers[0].commertialOffer
                              .ListPrice ? (
                              <strike className="precio-antes-2 m-1">
                                $
                                {
                                  item.items[0].sellers[0].commertialOffer
                                    .ListPrice
                                }
                              </strike>
                            ) : null}
                            <span className="precio-2 m-1">
                              ${item.items[0].sellers[0].commertialOffer.Price}
                            </span>
                          </div>
                          <button
                            className="search-button txt-1-r text-secundario"
                            onClick={() =>
                              addToCard(parseInt(item.items[0].itemId))
                            }
                          >
                            <img src={iconCart} /> Agregar
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </Col>
        )
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
          <img
            src={spinner}
            className="cart-spinner mb-5"
            style={{ width: "6.149vw" }}
          />
          <h5 className="tit-3-r text-g6">Procesando búsqueda</h5>
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  const { cart, products } = state;
  return {
    cart,
    products,
  };
}

function mapDistpachToProps(dispatch) {
  return bindActionCreators(
    {
      showCart,
      addItem,
      setProductNotFound,
      search,
      resetItems,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDistpachToProps)(withRouter(Search));
