import axios from 'axios';

const SEARCH = 'products/SEARCH';
const LOADING = 'products/LOADING';

const initialState = {
	items: null,
	loading: false
};

export default function products(state = initialState, action) {
	switch(action.type) {
	case SEARCH:
		return {
			...state,
			...action.payload
		};
	case LOADING:
		return {
			...state,
			...action.payload
		};
	default:
		return state;
	}
}

export const search = (term, callback) => (dispatch) => {
	dispatch({ type: SEARCH, payload: {items: null} });
	if(term === '') {
		dispatch({ type: SEARCH, payload: {items: null, loading: false} });
	} else {
		dispatch({ type: SEARCH, payload: {loading: true} });

		axios.get(`/api/catalog_system/pub/products/search/${term}`)
			.then((response) => {
				dispatch({ type: SEARCH, payload: {items: response.data, loading: false} });     
				callback(response.data)      
			})
	}
};

export const getSkuByEAN = (ean, callback) => (dispatch) => {
	axios.get(`/api/catalog_system/pvt/sku/stockkeepingunitbyean/${ean}`)
		.then((response) => {
			callback(response)
		})
		.catch((error) => {
			console.log("Error es",error)
			callback({data: {Id: undefined}, msg:error?.response?.data})
		})
};

export const resetItems = () => (dispatch) => {
	dispatch({ type: SEARCH, payload: {items: null, loading: false} });
};

