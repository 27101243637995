import * as React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Router from './router';
import {
  Container,
  Row,
  Col
} from 'reactstrap';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import MiniCart from './components/MiniCart/MiniCart';
import Timer from './components/Timer/Timer';
import { orderForm, addItem, updateItem } from './redux/modules/cart';
import history from './history';
import alert from './assets/alert.svg';
import check from './assets/check.svg';
import spinner from './assets/spinner.svg';

function App(props) {

  const { orderForm, loading, show } = props.cart;
  const { completedPurchase, isCheckout, processing } = props.checkout;

  return (
    <>
      <Header
        help={true}
        cancel={(orderForm && orderForm.items && orderForm.items.length) && !completedPurchase}
      /> 
      <div className="d-flex flex-column">
        <Container fluid className={`h-100 d-flex flex-column ${orderForm && orderForm.items && orderForm.items.length ? 'justify-content-start pt-0' : 'justify-content-center pt-0'}`}>
          
          {!loading && !processing ?
              <Row className="h-100">
                <Router history={history} />
                {!completedPurchase || show ?
                <Col xs={{size: 4, offset: 1}} className="h-100 d-flex flex-column cart-column">
                  {show ? <MiniCart /> : null}
                </Col>
                : null}
              </Row>
          :
            <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
              <img src={spinner} className="cart-spinner mb-5" style={{width: '6.149vw'}} />
              {loading ? <h5 className="tit-3-r text-g6">Estamos procesando tu producto</h5> : null}
              {processing ? <h5 className="tit-3-r text-g6">Estamos procesando tu pago</h5> : null}
            </div>
          }
          {!completedPurchase && isCheckout ? <Timer /> : null}
        </Container>
      </div>
      <Footer />
    </>
  )
}

function mapStateToProps(state) {
  const { cart, checkout } = state;
  return {
      cart,
      checkout
  };
}

function mapDistpachToProps(dispatch) {
  return bindActionCreators(
      {
        orderForm,
        addItem,
        updateItem
      },
      dispatch
  );
}

export default connect(mapStateToProps, mapDistpachToProps)(App);