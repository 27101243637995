import { createContext, useState } from "react";
require('dotenv').config()
export const UserContext = createContext();

export const UserProvider = (props) => {
  const [login, setLogin] = useState(true);

  const handleLogin = async ({ password, email }) => {
    const baseURL =
      process.env.STAGE=="QA" && process.env.ENV=="LOCAL"
        ? "https://qa.totem.delpueblofarmacias.com.ar"
        : `https://totem.delpueblofarmacias.com.ar`;

    return await fetch(`${baseURL}/api/admin/login`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          setLogin(true);
        }
        return res;
      })
      .catch((err) => err);
  };
  const logout = () => {
    setLogin(false);
  };
  return (
    <UserContext.Provider value={{ isLogged: login, handleLogin, logout }}>
      {props.children}
    </UserContext.Provider>
  );
};
