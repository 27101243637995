import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, FormGroup, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import { setDNI } from "../../redux/modules/checkout";
import MaskedInput from "react-maskedinput";
import "./Checkout.scss";
import { useOutside } from "../../hooks/useOutside";
import KeyboardComponent from "../../components/Keyboard/KeyboardComponent";

function DNI(props) {
  const { document } = props.checkout;
  const keyboard = React.useRef(null);
  const keyboardContainer = React.useRef(null);

  const { show, handleShow, handleHide } = useOutside(keyboardContainer);

  const [dni, setDni] = React.useState(document ? document : "");
  const [error, setError] = React.useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    // primeros 8 digitos porque keyboardComponent no soporta validaciones
    const dniNumber = dni.substring(0, 8);
    if (dniNumber.length === 8 && dniNumber.match(/^[0-9]+$/)) {
      props.setDNI({ dni: dniNumber });
      props.history.push("/checkout/register-success");
    } else {
      setError("Campo obligatorio");
    }
  };

  const handleChange = ({ target }) => {
    setDni(target.value);
    keyboard.current.setInput(target.value);
  };
  return (
    <Col className="d-flex flex-column">
      <Row className="align-items-center h-100">
        <Col
          sm={{ size: 10, offset: 2 }}
          className="d-flex flex-column"
          style={{ marginLeft: "14.9%" }}
        >
          <h3
            className="tit-2-r text-negro"
            style={{ marginBottom: "4.392vw" }}
          >
            ¿Cuál es tu número de DNI?
          </h3>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <div ref={keyboardContainer} onClick={handleShow}>
                  <FormGroup>
                    <MaskedInput
                      type="text"
                      name="dni"
                      placeholder="xx xxx xxx"
                      onChange={handleChange}
                      value={dni}
                      invalid={error}
                      className="form-control mb-3"
                      mask="11.111.111"
                      autoComplete="off"
                      placeholderChar="x"
                    />
                    <div className="t1-r text-alerta mb-2">{error}</div>
                  </FormGroup>
                  <KeyboardComponent
                    ref={keyboard}
                    type="number"
                    show={show}
                    onChange={setDni}
                    handleHide={handleHide}
                  />
                </div>
              </Col>
            </Row>

            <div className="d-flex align-items-center mt-5">
              <div className="t1-alt-b text-g6">
                <span className="text-principal">3</span>/3
              </div>
              <div className="ml-auto">
                <Button
                  color="link"
                  className="btn-3 text-g6"
                  style={{ width: 190 }}
                  onClick={() => props.history.push("/checkout/name")}
                >
                  Volver
                </Button>
                <Button
                  color="warning"
                  type="submit"
                  className="bg-principal border-principal text-blanco"
                  style={{ width: 190 }}
                >
                  Siguiente
                </Button>
              </div>
            </div>
          </form>
        </Col>
      </Row>
    </Col>
  );
}

function mapStateToProps(state) {
  const { checkout } = state;
  return {
    checkout,
  };
}

function mapDistpachToProps(dispatch) {
  return bindActionCreators(
    {
      setDNI,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDistpachToProps)(withRouter(DNI));
