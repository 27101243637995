import * as React from 'react'
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import listoSuave from '../../assets/listo-suave.svg';
import './Checkout.scss'


function RegisterSuccess(props) {
    React.useEffect(() => {
        setTimeout(() => {
            props.history.push('/checkout/payment-method')
        }, 1000)
    }, [])
    return (

      <Col className="d-flex flex-column justify-content-center">
        <Row>
          <Col sm={{size: 10, offset: 2}} className="d-flex flex-column text-center align-items-center" style={{marginLeft: '14.9%'}}>
            <img src={listoSuave} style={{width: '16.105vw'}} />
            <h3 className="tit-2-r text-negro font-montserrat">¡Listo!</h3>
            <p className="h4-r text-negro font-montserrat">Ya registramos tu usuario.</p>
          </Col>    
        </Row>
      </Col>        
    );
};

export default withRouter(RegisterSuccess);