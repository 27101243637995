import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col, Button, Input } from "reactstrap";
import { withRouter } from "react-router-dom";
import { getOrSaveEmail, setCheckout } from "../../redux/modules/checkout";
import Timer from "../../components/Timer/Timer";
import checkboxChecked from "../../assets/ok.svg";
import checkbox from "../../assets/checkbox.svg";
import "./Checkout.scss";
import KeyboardComponent from "../../components/Keyboard/KeyboardComponent";
import { useOutside } from "../../hooks/useOutside";

function Email(props) {
  const { email, timer } = props.checkout;
  const keyboard = React.useRef(null);
  const keyboardContainer = React.useRef(null);

  const { show, handleShow, handleHide } = useOutside(keyboardContainer);

  const [userEmail, setUserEmail] = React.useState(email ? email : "");
  const [error, setError] = React.useState(false);
  const [isNewsletterOptIn, setIsNewsletterOptIn] = React.useState(true);

  React.useEffect(() => {
    props.setCheckout();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      props.getOrSaveEmail(userEmail, isNewsletterOptIn, props.history);
    } else {
      setError("Ingrese un email válido");
    }
  };

  const handleChange = ({ target }) => {
    setUserEmail(target.value);
    keyboard.current.setInput(target.value);
  };

  return (
    <Col className="d-flex flex-column">
      <Row className="align-items-center h-100">
        <Col
          sm={{ size: 10, offset: 2 }}
          className="d-flex flex-column"
          style={{ marginLeft: "14.9%" }}
        >
          <form onSubmit={handleSubmit}>
            <h3
              ref={keyboard}
              className="tit-2-r text-negro"
              style={{ marginBottom: "4.392vw" }}
            >
              Ingresá tu mail para continuar tu compra
            </h3>
            {error ? (
              <span className="d-block t1-r text-alerta mb-2">{error}</span>
            ) : null}

            <div
              className="buscador"
              ref={keyboardContainer}
              onClick={handleShow}
            >
              <Input
                type="text"
                name="email"
                value={userEmail}
                onChange={handleChange}
                invalid={error}
                autoComplete="off"
                placeholder="usuario@gmail.com"
                className="mb-3"
              />
              <KeyboardComponent
                ref={keyboard}
                show={show}
                onChange={setUserEmail}
                handleHide={handleHide}
              />
            </div>

            <div
              className="tr-1"
              onClick={() => setIsNewsletterOptIn(!isNewsletterOptIn)}
            >
              <img
                src={isNewsletterOptIn ? checkboxChecked : checkbox}
                style={{ width: "1.757vw" }}
                alt={isNewsletterOptIn ? "Tildado" : "Destildado"}
              />
              ¿Querés recibir nuestras ofertas y promociones?
            </div>
            <div className="d-flex align-items-center mt-5">
              <div className="t1-alt-b text-g6">
                <span className="text-principal">1</span>/3
              </div>
              <div className="ml-auto">
                <Button
                  color="link"
                  className="btn-3 text-g6"
                  style={{ width: 190 }}
                  onClick={() => props.history.push("/cart")}
                >
                  Volver
                </Button>
                <Button
                  type="submit"
                  color="warning"
                  className="bg-principal border-principal text-blanco"
                  style={{ width: 190 }}
                >
                  Siguiente
                </Button>
              </div>
            </div>
          </form>
        </Col>
      </Row>
    </Col>
  );
}

function mapStateToProps(state) {
  const { checkout } = state;
  return {
    checkout,
  };
}

function mapDistpachToProps(dispatch) {
  return bindActionCreators(
    {
      getOrSaveEmail,
      setCheckout,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDistpachToProps)(withRouter(Email));
