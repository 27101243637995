import * as React from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container, Row, Col, Card, CardBody, Button, Modal, ModalBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { getSkuByEAN } from '../../redux/modules/products';
import { orderForm, addItem, updateItem, showCart } from '../../redux/modules/cart';
import Header from '../../components/Header/Header';
import HowItWorks from '../../components/HowItWorks/HowItWorks';
import lector from '../../assets/lector.svg';
import ayuda from '../../assets/ayuda.svg';
import carrito from '../../assets/carrito.svg';
import quitar from '../../assets/quitar.svg';
import spinner from '../../assets/spinner.svg';
import alert from '../../assets/alert.svg';
import check from '../../assets/check.svg';
import './Cart.scss'

function Cart(props) {
  const [modalHelp, setModalHelp] = React.useState(false)

  const { orderForm, loading, productNotFound } = props.cart;

  React.useEffect(() => {
    props.showCart(true)
  }, [])

  return (
    <>
      {/* <Container fluid className={`h-100 d-flex flex-column ${orderForm && orderForm.items && orderForm.items.length ? 'justify-content-start pt-5' : 'justify-content-center'}`}> */}
          {/* <Row> */}
            <Col className={`${!(orderForm && orderForm.items && orderForm.items.length) ? 'd-flex flex-column justify-content-center ' : ' pt-5 '}`} xs={{size: 6, offset: (orderForm && orderForm.items && orderForm.items.length ? 1 : 3)}}>

              
              {productNotFound ?
              <>
                <h3 className="btn-1 text-negro">¡Lo sentimos! No encontramos <span className="btn-1 text-alerta ml-5">“{productNotFound}”</span></h3>
                <p className="h4-r" style={{marginBottom: '4.246vw'}}>Volvé a buscar por nombre o escaneá tu producto</p>
              </>
              :null}
              <Card>
                <CardBody style={{padding: orderForm && orderForm.items && orderForm.items.length ? '2.755vw 0 1.684vw' : '4.539vw 2.635vw'}}>
                  {!(orderForm && orderForm.items && orderForm.items.length) ?
                    <div className="d-flex align-items-center">
                      <img src={lector} style={{width: '16.179vw'}} />
                      <div className="pl-5">
                        <h5 className="btn-1 text-principal">Escaneá tu primer producto</h5>
                        <h6 className="txt-1-r text-g9">Coloca el escáner sobre el código de barras para comenzar tu compra.</h6>
                      </div>
                    </div>
                  :
                    <div className="d-flex flex-column align-items-center text-center">
                      <img src={lector} style={{width: '16.179vw'}} />
                      <div className="pl-5">
                        <p className="txt-2-r text-g6 m-0">Podés seguir escaneando el resto de los productos</p>
                      </div>
                    </div>
                  }
                  
                </CardBody>
              </Card>
              <div className="d-flex mt-4">
                <Button outline color="secondary" style={{width: '24vw', borderRadius: '0.87848vw', borderWidth: 2}} className="mr-4 d-flex align-items-center border-g5 btn-2 text-g8 bg-blanco" onClick={() => setModalHelp(true)}><img src={ayuda} style={{width: '2.635vw'}}/> Necesito ayuda</Button>
                <Button outline color="success" style={{borderRadius: '0.87848vw', borderWidth: 2}} className="border-secundario text-secundario txt-1-b bg-blanco" block onClick={() => props.history.push('/cart/search')}>¿No funciona el código de barras?</Button>
              </div>
            </Col>
          {/* </Row> */}
      {/* </Container> */}

      <Modal isOpen={modalHelp} toggle={() => setModalHelp(false)}>
        <ModalBody>
          <div className="d-flex flex-column justify-content-center">
            <h2 className="text-center tit-3-b text-g9 mb-5">Por favor, acercate a la zona de cajas para recibir ayuda</h2>
            <Row>
              <Col>
                <Button color="warning" className="bg-principal text-blanco" block onClick={() => setModalHelp(false)}>OK</Button>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>

    </>
  );
};

function mapStateToProps(state) {
  const { cart } = state;
  return {
      cart
  };
}

function mapDistpachToProps(dispatch) {
  return bindActionCreators(
      {
        orderForm,
        getSkuByEAN,
        addItem,
        updateItem,
        showCart
      },
      dispatch
  );
}

export default connect(mapStateToProps, mapDistpachToProps)(withRouter(Cart));