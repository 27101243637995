import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Input, FormGroup, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import { setName } from "../../redux/modules/checkout";
import KeyboardComponent from "../../components/Keyboard/KeyboardComponent";
import "./Checkout.scss";
import { useOutside } from "../../hooks/useOutside";

function Name(props) {
  const keyboard = useRef(null);
  const keyboardContainer = useRef(null);

  const keyboard2 = useRef(null);
  const keyboardContainer2 = useRef(null);

  const {
    show: showKeyboard2,
    handleShow: handleShow2,
    handleHide: handleHide2,
  } = useOutside(keyboardContainer2);

  const { show, handleShow, handleHide } = useOutside(keyboardContainer);

  const [firstName, setFirstName] = useState(
    props.checkout.firstName ? props.checkout.firstName : ""
  );
  const [lastName, setLastName] = useState(
    props.checkout.lastName ? props.checkout.lastName : ""
  );

  const [error, setError] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    if (firstName.length > 0 && lastName.length > 0) {
      props.setName({ firstName, lastName });
      props.history.push("/checkout/dni");
    } else {
      setError("Campo obligatorio");
    }
  };

  const handleChange = ({ target }) => {
    if (target.name === "firstName") {
      setFirstName(target.value);
      keyboard.current.setInput(target.value);
    } else {
      setLastName(target.value);
      keyboard2.current.setInput(target.value);
    }
  };

  return (
    <Col className="d-flex flex-column">
      <Row className="align-items-center h-100">
        <Col
          sm={{ size: 10, offset: 2 }}
          className="d-flex flex-column"
          style={{ marginLeft: "14.9%" }}
        >
          <h3
            className="tit-2-r text-negro"
            style={{ marginBottom: "4.392vw" }}
          >
            ¿Cuál es tu nombre?
          </h3>

          <form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <div
                  ref={keyboardContainer}
                  onClick={handleShow}
                  className="nameKey"
                >
                  <FormGroup>
                    <Input
                      type="text"
                      name="firstName"
                      onKeyUp={handleChange}
                      onChange={handleChange}
                      value={firstName}
                      invalid={error}
                      placeholder="Nombre"
                      className="mb-3"
                      autoComplete="off"
                    />

                    <div className="t1-r text-alerta mb-2">{error}</div>
                  </FormGroup>
                  {show && (
                    <KeyboardComponent
                      ref={keyboard}
                      show={show}
                      onChange={setFirstName}
                      handleHide={handleHide}
                    />
                  )}
                </div>
              </Col>
              <Col>
                <div ref={keyboardContainer2} onClick={handleShow2}>
                  <FormGroup>
                    <Input
                      type="text"
                      name="lastName"
                      onKeyUp={handleChange}
                      onChange={handleChange}
                      value={lastName}
                      invalid={error}
                      placeholder="Apellido"
                      className="mb-3"
                      autoComplete="off"
                    />
                    <div className="t1-r text-alerta mb-2">{error}</div>
                  </FormGroup>
                  {showKeyboard2 && (
                    <KeyboardComponent
                      ref={keyboard2}
                      show={showKeyboard2}
                      onChange={setLastName}
                      handleHide={handleHide2}
                    />
                  )}
                </div>
              </Col>
            </Row>

            <div className="d-flex align-items-center mt-5">
              <div className="t1-alt-b text-g6">
                <span className="text-principal">2</span>/3
              </div>
              <div className="ml-auto">
                <Button
                  color="link"
                  className="btn-3 text-g6"
                  style={{ width: 190 }}
                  onClick={() => props.history.push("/checkout/email")}
                >
                  Volver
                </Button>
                <Button
                  color="warning"
                  className="bg-principal border-principal text-blanco"
                  style={{ width: 190 }}
                >
                  Siguiente
                </Button>
              </div>
            </div>
          </form>
        </Col>
      </Row>
    </Col>
  );
}

function mapStateToProps(state) {
  const { checkout } = state;
  return {
    checkout,
  };
}

function mapDistpachToProps(dispatch) {
  return bindActionCreators(
    {
      setName,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDistpachToProps)(withRouter(Name));
