import React from "react";

const Point = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.74 8.33001C17.5621 8.13416 17.3138 8.01683 17.0495 8.00371C16.7852 7.99059 16.5265 8.08275 16.33 8.26001L10.42 13.64L7.68001 11.12C7.48375 10.9397 7.22388 10.8447 6.95758 10.8559C6.69128 10.8672 6.44035 10.9837 6.26001 11.18C6.07966 11.3763 5.98466 11.6361 5.99591 11.9024C6.00717 12.1687 6.12375 12.4197 6.32001 12.6L9.74001 15.74C9.92435 15.9087 10.1652 16.0022 10.415 16.0022C10.6649 16.0022 10.9057 15.9087 11.09 15.74L17.67 9.74001C17.8659 9.56206 17.9832 9.31375 17.9963 9.04947C18.0094 8.78518 17.9173 8.52647 17.74 8.33001Z"
        fill="#FF7D00"
      />
      <path
        d="M24 12C24 9.62663 23.2962 7.30655 21.9776 5.33316C20.6591 3.35977 18.7849 1.8217 16.5922 0.913451C14.3995 0.00519943 11.9867 -0.232441 9.65892 0.230582C7.33115 0.693605 5.19295 1.83649 3.51472 3.51472C1.83649 5.19295 0.693605 7.33115 0.230582 9.65892C-0.232441 11.9867 0.00519943 14.3995 0.913451 16.5922C1.8217 18.7849 3.35977 20.6591 5.33316 21.9776C7.30655 23.2962 9.62663 24 12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12ZM2.00001 12C2.00001 10.0222 2.58649 8.08879 3.68531 6.4443C4.78412 4.79981 6.34591 3.51809 8.17317 2.76121C10.0004 2.00433 12.0111 1.8063 13.9509 2.19215C15.8907 2.578 17.6725 3.53041 19.0711 4.92894C20.4696 6.32746 21.422 8.10929 21.8079 10.0491C22.1937 11.9889 21.9957 13.9996 21.2388 15.8268C20.4819 17.6541 19.2002 19.2159 17.5557 20.3147C15.9112 21.4135 13.9778 22 12 22C9.34784 22 6.8043 20.9464 4.92894 19.0711C3.05357 17.1957 2.00001 14.6522 2.00001 12Z"
        fill="#FF7D00"
      />
    </svg>
  );
};

export default Point;
