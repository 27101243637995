import React from "react";
import { useHistory, withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { orderForm } from "../../redux/modules/cart";
import KeyboardWrapper from "../../components/Keyboard/KeyboardWrapper";
import SucursalesList from "./SucursalesList";
import iconSearch from "../../assets/search.svg";
import "../../styles/login/sucursales.scss";

const Sucursales = () => {
  const history = useHistory();
  const [sucursalSeleccionada, setSucursalSeleccionada] = React.useState(null);
  const [search, setSearch] = React.useState("");
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  // function to save sucursalSeleccionada in localStorage
  const setCurrentPickup = () => {
    localStorage.setItem("PickupId", sucursalSeleccionada);
    dispatch(orderForm(sucursalSeleccionada));
    setTimeout(() => {
      history.push("/");
    }, 2000);
  };

  React.useEffect(() => {
    setSucursalSeleccionada(localStorage.getItem("PickupId"));
  }, []);
  return (
    <div className="sucursales">
      <h1>¡Bienvenido!</h1>
      <h2>Para comenzar seleccioná tu sucursal</h2>
      <form onSubmit={handleSubmit}>
        <img src={iconSearch} alt="lupita icono" width={20} height={20} />
        <KeyboardWrapper
          value={search}
          setValue={setSearch}
          otherInputsProps={{
            type: "text",
            placeholder: "Buscar sucursal",
            name: "",
          }}
        />
        <button type="submit">Buscar</button>
      </form>
      <SucursalesList
        search={search}
        sucursalSeleccionada={sucursalSeleccionada}
        setSucursalSeleccionada={setSucursalSeleccionada}
      />
      <button
        className="confirmation"
        onClick={setCurrentPickup}
        disabled={!sucursalSeleccionada}
      >
        Confirmar Sucursal
      </button>
    </div>
  );
};

function mapStateToProps(state) {
  const { cart } = state;
  return {
    cart,
  };
}

function mapDistpachToProps(dispatch) {
  return bindActionCreators(
    {
      orderForm,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDistpachToProps
)(withRouter(Sucursales));
