import React, { Children, useRef } from "react";
import { useOutside } from "../../hooks/useOutside";
import KeyboardComponent from "./KeyboardComponent";

const KeyboardWrapper = ({
  value,
  setValue,
  otherInputsProps,
  type = "complete",
  error = "",
}) => {
  const keyboard = useRef(null);
  const keyboardContainer = useRef(null);

  const { show, handleShow, handleHide } = useOutside(keyboardContainer);

  const handleChange = ({ target }) => {
    setValue(target.value);
    keyboard.current.setInput(target.value);
  };
  return (
    <div ref={keyboardContainer} onClick={handleShow}>
      <input {...otherInputsProps} onChange={handleChange} value={value} />
      {error && <div className="t1-r text-alerta mb-2">{error}</div>}
      {show && (
        <KeyboardComponent
          ref={keyboard}
          show={show}
          onChange={setValue}
          handleHide={handleHide}
          type={type}
        />
      )}
    </div>
  );
};

export default KeyboardWrapper;
