import axios from "axios";
import React from "react";
import Check from "./Check";
import Point from "./Point";

const SucursalesComponent = ({ search, sucursalSeleccionada, setSucursalSeleccionada }) => {
  const [sucursalesArray, setSucursalesArray] = React.useState([]);
 
  const setCurrentPickup = (id) => {
    setSucursalSeleccionada(id);
  };
  React.useEffect(() => {
    axios
      .get(`/api/logistics/pvt/configuration/pickuppoints`)
      .then((result) => setSucursalesArray(result.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <ul className="sucursales-list">
      {sucursalesArray?.length > 0 &&
        sucursalesArray
          .filter((sucursal) =>
            sucursal.name
              .toLocaleLowerCase()
              .includes(search.toLocaleLowerCase())
          )
          .map((sucursal, index) => {
            return (
              <li
                key={index}
                className={`sucursal ${
                  sucursal.isActive
                    ? sucursalSeleccionada === sucursal.id
                      ? "active"
                      : ""
                    : "disabled"
                }`}
                onClick={() => setCurrentPickup(sucursal.id)}
              >
                <div className="sucursal-nombre">
                  <div className="point">
                    <Point />
                  </div>
                  <span>{sucursal.name}</span>
                </div>
                <div className="sucursal-info">
                  <div>
                    ID: <span>{sucursal.id}</span>{" "}
                  </div>
                  <div>
                    Direccion: <span>{sucursal.address.street}</span>
                    <span>{sucursal.address.number}</span>
                  </div>
                </div>
                <div className="check">
                  {sucursalSeleccionada === sucursal.id ? (
                    <Check />
                  ) : (
                    <div className="circle" />
                  )}
                </div>
              </li>
            );
          })}
    </ul>
  );
};


export default SucursalesComponent;