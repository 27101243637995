import { combineReducers } from "redux";
import products from "./products";
import cart from "./cart";
import checkout from "./checkout";

const rootReducer = combineReducers({
    products,
    cart,
    checkout
});

export default rootReducer;
