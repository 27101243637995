import React from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import {useLogin} from "./hooks/useLogin";
import Login from "./containers/Login/Login";
import Sucursales from "./containers/Login/Sucursales";
import Start from "./containers/Start/Start";
import Cart from "./containers/Cart/Cart";
import Search from "./containers/Cart/Search";
import Email from "./containers/Checkout/Email";
import Name from "./containers/Checkout/Name";
import DNI from "./containers/Checkout/DNI";
import RegisterSuccess from "./containers/Checkout/RegisterSuccess";
import PaymentMethod from "./containers/Checkout/PaymentMethod";
import PaymentCard from "./containers/Checkout/PaymentCard";
import PaymentCVV from "./containers/Checkout/PaymentCVV";
import PaymentSuccess from "./containers/Checkout/PaymentSuccess";
import history from "./history";

export default function Routers() {
  const {isLogged} = useLogin();
  return (
    <Router history={history}>
      <Switch>
        {isLogged && <Route path="/login/sucursales" component={Sucursales} />}

        <Route exact path="/login" component={Login} />
        {!localStorage.getItem("PickupId") && <Redirect to="/login" />}

        <Route path="/checkout/success">
          <PaymentSuccess />
        </Route>
        <Route path="/checkout/payment-cvv">
          <PaymentCVV />
        </Route>
        <Route path="/checkout/payment-card">
          <PaymentCard />
        </Route>
        <Route path="/checkout/payment-method">
          <PaymentMethod />
        </Route>
        <Route path="/checkout/register-success">
          <RegisterSuccess />
        </Route>
        <Route path="/checkout/dni">
          <DNI />
        </Route>
        <Route path="/checkout/name">
          <Name />
        </Route>
        <Route path="/checkout/email">
          <Email />
        </Route>
        <Route path="/cart/search">
          <Search />
        </Route>
        <Route path="/cart">
          <Cart />
        </Route>
        <Route path="/">
          <Start />
        </Route>
      </Switch>
    </Router>
  );
}
