import * as React from 'react'
import { Row, Col, Button, Modal, ModalBody, Container } from 'reactstrap';
import HowItWorks from '../HowItWorks/HowItWorks';

import './Header.scss'
import logo from '../../assets/logo.svg'
import close from '../../assets/cancel.svg';
import ayuda from '../../assets/ayuda2.svg';


export default function Header({help, cancel}) {
  const [modalCancel, setModalCancel] = React.useState(false)
  const [modalHowItWorks, setModalHowItWorks] = React.useState(false)

  return (
    <>
      <div className='header d-flex justify-content-center align-items-center border-g4'>
        <Container className="header-conteiner d-flex justify-content-center align-items-center" fluid>
          <div className="d-flex align-items-center mr-auto" style={{visibility: help ? 'visible' : 'hidden'}} onClick={() => setModalHowItWorks(true)}>
            <img src={ayuda} className="mr-2" />
            <p className="text-uppercase m-0 btn-btn3 text-g8">ver instrucciones</p>
          </div>
          <img className='header-logo' src={logo}/>
          <div className="d-flex align-items-center ml-auto" style={{visibility: cancel ? 'visible' : 'hidden'}}>
            <img src={close} className="mr-2" />
            <p className="text-uppercase m-0 btn-btn3 text-g8" onClick={() => setModalCancel(true)}>Cancelar Compra</p>
          </div>
        </Container>
      </div>

      <Modal isOpen={modalCancel} toggle={() => setModalCancel(false)}>
        <ModalBody>
          <h2 className="tit-3-r text-g9">¿Seguro que querés cancelar la compra?</h2>
          <p className="txt-1-r text-g8 mb-5">Al cancelar, la información de tu compra se perderá.</p>
          <Row>
            <Col>
              <Button outline color="warning" className="text-principal" block style={{border: 'none'}} onClick={() => setModalCancel(false)}>VOLVER ATRÁS</Button>
            </Col>
            <Col>
              <Button color="warning" className="bg-principal text-blanco" block onClick={() => window.location = '/'}>Si, cancelar compra</Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <Modal className="how-it-works-modal" isOpen={modalHowItWorks} toggle={() => setModalHowItWorks(false)}>
        <ModalBody>
          <h2 className="tit-2-r text-g9 text-center mb-4">Cómo funciona</h2>
          <HowItWorks modal={true} onClose={() => setModalHowItWorks(false)} />
        </ModalBody>
      </Modal>
    </>
  );
};
