import React from "react";

const Check = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.74 14.33C23.5621 14.1342 23.3138 14.0168 23.0495 14.0037C22.7852 13.9906 22.5265 14.0827 22.33 14.26L16.42 19.64L13.68 17.12C13.4837 16.9397 13.2239 16.8447 12.9576 16.8559C12.6913 16.8672 12.4404 16.9837 12.26 17.18C12.0797 17.3763 11.9847 17.6361 11.9959 17.9024C12.0072 18.1687 12.1237 18.4197 12.32 18.6L15.74 21.74C15.9244 21.9087 16.1652 22.0022 16.415 22.0022C16.6649 22.0022 16.9057 21.9087 17.09 21.74L23.67 15.74C23.8659 15.5621 23.9832 15.3138 23.9963 15.0495C24.0094 14.7852 23.9173 14.5265 23.74 14.33Z"
        fill="#FF7D00"
      />
      <path
        d="M30 18C30 15.6266 29.2962 13.3066 27.9776 11.3332C26.6591 9.35977 24.7849 7.8217 22.5922 6.91345C20.3995 6.0052 17.9867 5.76756 15.6589 6.23058C13.3312 6.6936 11.193 7.83649 9.51472 9.51472C7.83649 11.193 6.6936 13.3312 6.23058 15.6589C5.76756 17.9867 6.0052 20.3995 6.91345 22.5922C7.8217 24.7849 9.35977 26.6591 11.3332 27.9776C13.3066 29.2962 15.6266 30 18 30C21.1826 30 24.2348 28.7357 26.4853 26.4853C28.7357 24.2348 30 21.1826 30 18ZM8.00001 18C8.00001 16.0222 8.58649 14.0888 9.68531 12.4443C10.7841 10.7998 12.3459 9.51809 14.1732 8.76121C16.0004 8.00433 18.0111 7.8063 19.9509 8.19215C21.8907 8.578 23.6725 9.53041 25.0711 10.9289C26.4696 12.3275 27.422 14.1093 27.8079 16.0491C28.1937 17.9889 27.9957 19.9996 27.2388 21.8268C26.4819 23.6541 25.2002 25.2159 23.5557 26.3147C21.9112 27.4135 19.9778 28 18 28C15.3478 28 12.8043 26.9464 10.9289 25.0711C9.05357 23.1957 8.00001 20.6522 8.00001 18Z"
        fill="#FF7D00"
      />
    </svg>
  );
};

export default Check;
