import * as React from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { setPaymentCard } from '../../redux/modules/checkout';
import listo from '../../assets/listo.svg';
import './Checkout.scss'


function PaymentSuccess(props) {
  return (
    <Col className="d-flex flex-column text-center">
      <Row className="align-items-center h-100">
        <Col sm={{size: 6, offset: 3}} className="d-flex flex-column align-items-center">
          <img src={listo} />
          <h1 className="tit-1-b text-negro mb-4">¡Gracias por tu compra!</h1>
          <p className="tit-3-r text-g9 mb-5">Por favor, dirigite a la zona de empaque con tu ticket de compra.</p>
          <Button  color="warning" className="bg-principal border-principal text-blanco" style={{width: 190}} onClick={() => window.location = '/'}>Finalizar proceso</Button>
        </Col>    
      </Row>
    </Col> 
  );
};

function mapStateToProps(state) {
  const { checkout } = state;
  return {
      checkout
  };
}

function mapDistpachToProps(dispatch) {
  return bindActionCreators(
      {
        setPaymentCard
      },
      dispatch
  );
}

export default connect(mapStateToProps, mapDistpachToProps)(withRouter(PaymentSuccess));